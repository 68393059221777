import React from 'react';
import { Box, Heading } from '@chakra-ui/core';
import Layout from '../components/Layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <Box textAlign="center">
        <Heading>Page not found.</Heading>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
